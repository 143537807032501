import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '@services/navigation/navigation.service';
import { LoggerService } from '@app/core/services/log/logger.service';
import { environment } from '@environments/environment';
import { BehaviorSubject } from 'rxjs';

export const LANGUAGE_ITEM_KNAME = 'language';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  private readonly translateService = inject(TranslateService);
  private readonly navigationService = inject(NavigationService);
  private readonly logger = inject(LoggerService);

  public readonly supportedLanguages: string[] = environment.translation.supportedLanguages;
  private currentLang = new BehaviorSubject<string>('');
  currentLang$ = this.currentLang.asObservable();


  setLanguageByNavigator(): void {
    const storedLang = localStorage.getItem(LANGUAGE_ITEM_KNAME);
    const browserLang = this.translateService.getBrowserLang();
    
    this.logger.debug(`TranslationService : stored language: ${storedLang}`);
    this.logger.debug(`TranslationService : browser language: ${browserLang}`);

    let lang = '';

    if (storedLang && this.checkSupport(storedLang)) {
      lang = storedLang;
      this.logger.debug(`TranslationService : using stored language: ${lang}`);
    } else if (browserLang && this.checkSupport(browserLang)) {
      lang = browserLang;
      this.logger.debug(`TranslationService : using browser language: ${lang}`);
    } else {
      lang = this.translateService.getDefaultLang();
      this.logger.debug(`TranslationService : using default language: ${lang}`);
    }

    this.setLanguage(lang);
  }

  setLanguage(lang: string): void {
    this.logger.debug(`TranslationService : setting language to ${lang}`);

    if (!this.checkSupport(lang)) {
      this.logger.warn(`TranslationService : unsupported language ${lang}, falling back to default`);
      lang = this.translateService.getDefaultLang();
    }

    localStorage.setItem(LANGUAGE_ITEM_KNAME, lang);
    this.navigationService.setUserLang(lang);
    this.translateService.use(lang);
    this.currentLang.next(lang);
  }

  getCurrentLang(): string {
    return this.currentLang.value;
  }

  private checkSupport(lang: string): boolean {
    return this.supportedLanguages.includes(lang);
  }
}
